import React from "react";
import { Helmet } from "react-helmet";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Container from "../components/Container";

export default function NotFoundPage() {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="leading-relaxed bg-primary tracking-wide flex flex-col overflow-y-hidden font-custom min-h-screen">
        <NavBar />
        <Container>This is not the page you are looking for.</Container>

        <Footer />
      </div>
    </>
  );
}
